:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Bebas Neue", serif;
  color: #3f4444;
}

body {
  margin: 0 !important;
  padding: 0;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  color: #3f4444 !important;
  box-sizing: border-box;
  background-color: #f7f7f7 !important;
  font-family: "Noto Serif", serif !important;
}

.css-1vovazs {
  font-family: "Noto Serif", serif !important;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  color: #3f4444 !important;
  display: inline-block;
  font-size: 16px;
  font-family: "Noto Serif", serif;
  font-weight: 700;
  line-height: 16px;
  text-decoration: underline;
  transition: 0.5s ease;
  text-decoration-color: #3f4444 !important;
}

a:hover {
  color: #1976d2 !important;
  transition: 0.5s ease;
  text-decoration-color: #1976d2 !important;
}

h3 {
  color: #333539;
  margin: 0 0 15px 0;
  display: block;
  font-size: 24px;
  font-family: "Bebas Neue", serif;
  font-weight: 400;
  line-height: 24px;
}

.App {
  position: relative;
}

fieldset {
  display: none !important;
}

.fromControl {
  margin: 20px 0;
}

/* Form Css */

.MuiFormLabel-root {
  color: #3f4444 !important;
  margin: 0px 0px 8px 0px;
  display: flex;
  font-size: 14px !important;
  font-family: "Noto Serif", serif !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  text-transform: capitalize;
}

input {
  color: #3f4444;
  width: 100%;
  border: 1px solid #fff;
  min-height: 40px;
  outline: none;
  padding: 12px 20px;
  font-size: 16px;
  background: #fff !important;
  transition: 0.5s ease;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  line-height: 24px;
  border-radius: 30px;
}

input::placeholder {
  color: #3f444480;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.MuiSelect-outlined span {
  color: #3f444480;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Noto Serif", serif;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  box-shadow: 0 0 0px 1000px #fff inset;
}

input:hover,
input:focus,
input:active {
  border: 1px solid rgba(63, 68, 68, 0.12);
  transition: 0.5s ease;
}

input:disabled,
textarea:disabled {
  background-color: rgb(227 221 210 / 20%) !important;
  border-color: rgb(227 221 210 / 20%) !important;
}

.tableLayout{
  table-layout: fixed;
}

.MuiTableCell-head {
  color: #3f4444 !important;
  padding: 15px 12px !important;
  font-size: 18px !important;
  background: #f7f7f7 !important;
  font-family: "Bebas Neue", serif !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  border-color: #dddddd !important;
  border-bottom: none !important;
  min-width: 150px !important;
}

.MuiTable-root > p {
  padding: 5px 10px;
  font-size: 14px;
  margin: 0;
}

.MuiTableCell-body {
  color: #3f4444 !important;
  padding: 10px 12px !important;
  font-size: 14px !important;
  font-family: "Noto Serif", serif !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  min-width: 150px !important;
  border-color: #dddddd !important;
  /* word-break: break-word !important; */
  /* vertical-align: top !important; */
}

.LeftSpace {
  padding: 0 0 0 210px;
}

.MuiPopover-paper {
  overflow-y: unset !important;
  overflow-x: unset !important;
}

.MuiSelect-outlined {
  border-color: transparent !important;
}

fieldset {
  border-color: transparent !important;
}

.MuiOutlinedInput-root {
  width: 100%;
  overflow: hidden;
  border-radius: 32px !important;
  font-family: "Noto Serif", serif !important;
}

fieldset:hover {
  border-color: rgba(63, 68, 68, 0.12);
}

/* date picker css */
.PrivatePickersSlideTransition-root::-webkit-scrollbar-thumb:horizontal,
.PrivatePickersFadeTransitionGroup-root::-webkit-scrollbar-thumb:horizontal {
  appearance: none;
  width: 0;
  height: 0;
}

.PrivatePickersSlideTransition-root::-webkit-scrollbar-thumb:vertical,
.PrivatePickersFadeTransitionGroup-root::-webkit-scrollbar-thumb:vertical {
  appearance: none;
  width: 0;
  height: 0;
}

.MuiDialog-paper {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 10px 15px -3px #1018281a !important;
  border-radius: 6px !important;
}

body .PrivatePickersFadeTransitionGroup-root {
  color: #3f4444;
  font-family: "Bebas Neue", serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

body .MuiTypography-root.MuiTypography-caption,
body .MuiPickersDay-root {
  color: #3f4444 !important;
  font-family: "Noto Serif", serif;
  font-size: 16px;
  font-weight: 400 !important;
  line-height: 24px;
  border: none !important;
}

.MuiDateRangePickerDay-rangeIntervalPreview {
  border: none;
}

body .MuiPickersDay-today {
  border: none !important;
  color: #f0730a !important;
  background-color: transparent;
}

.MuiDialogActions-spacing button {
  border-radius: 30px;
  box-shadow: 0 1px 2px rgb(16 24 40 / 5%);
  color: #3f4444 !important;
  font-family: "Bebas Neue", serif;
  font-size: 20px;
  line-height: 20px;
  padding: 10px;
  width: 50%;
  margin: 10px 0;
}

.MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight {
  background-color: rgb(255 194 0 / 60%) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  /* background-color: rgb(255 194 0 / 60%) !important; */
}

.MuiDialogActions-spacing button:first-child {
  background-color: #e3ddd2;
}

.MuiDialogActions-spacing button:last-child {
  background-color: #ffc200;
}

.MuiButtonBase-root .MuiPickersDay-root:hover,
.MuiButtonBase-root .MuiPickersDay-root:focus {
  background-color: #ffc200 !important;
}

.PrivatePickersSlideTransition-root {
  /* min-height: 228px !important; */
  border-bottom: 1px solid #ddd;
}

.css-1dozdou {
  border-bottom: 1px solid #dddddd;
  margin: 12px 0 15px !important;
  max-height: 60px !important;
  min-height: 60px !important;
  padding: 12px 0px 15px !important;
}

.MuiDialogContent-root {
  padding: 0 15px !important;
}

.MuiDialogContent-root svg {
  color: #3f4444;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #ffc200 !important;
}

.MuiIconButton-edgeStart,
.MuiIconButton-edgeEnd {
  padding: 0;
}

.MuiAppBar-positionFixed {
  background-color: #e3ddd2 !important;
}

body .Calander-datapickerBox-30 fieldset {
  border-color: transparent !important;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.toolbarTitle {
  color: #3f4444;
  font-family: Bebas Neue, serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

.css-18puooo {
  width: 8px;
}

/* date picker css end */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

/* spinner btn disable */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* select box */
body .MuiSelect-select {
  border-radius: 32px !important;
  width: 100% !important;
  /* display: table !important; */
  background: #fff !important;
  border: 1px solid #fff !important;
  padding: 12px 40px 12px 20px !important;
  color: #3f4444;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  min-height: 45px !important;
  position: relative !important;
  transition: 0.5s ease;
  overflow: hidden !important;
  box-sizing: border-box !important;
  font-family: "Noto Serif", serif !important;
}

body .MuiSelect-select:hover,
body .MuiSelect-select:focus,
body .MuiSelect-select:active {
  border: 1px solid rgba(63, 68, 68, 0.12) !important;
  transition: 0.5s ease;
}

body .MuiSelect-select::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  border-left: 0;
  border-style: solid;
  margin: 5px 0;
  border-width: 8px;
  border-radius: 4px;
  border-color: #3f4444;
  border-right-color: transparent;
  border-left-color: transparent;
  transition: 0.3s ease;
}

.MuiMenuItem-root:hover,
.MuiMenuItem-root:active {
  background-color: #f1eedb !important;
}

body .MuiSelect-select[aria-expanded="false"]::after {
  border-bottom-color: transparent;
}

body .MuiSelect-select[aria-expanded="true"]::after {
  border-top-color: transparent;
}

.MuiSelect-icon {
  display: none !important;
}

body .MuiList-root .MuiMenuItem-root span {
  color: #3f4444;
  font-family: "Noto Serif", serif !important;
  font-size: 16px !important;
  font-weight: 400 ;
  line-height: 20px !important;
  padding: 0;
}

.MuiMenu-paper {
  padding: 0px 0 !important;
  border-radius: 32px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root .MuiListItemIcon-root {
  min-width: 25px;
}

.MuiMenu-list {
  border-radius: 30px !important;
  /* padding: 0 !important; */
  overflow-y: auto;
  max-height: 250px;
}


.MuiMenu-list::-webkit-scrollbar {
  -webkit-appearance: none;
}

.MuiMenu-list:-webkit-scrollbar:vertical {
  width: 0;
}

.MuiMenu-list::-webkit-scrollbar:horizontal {
  height: 0;
}

/* .MuiMenu-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #fff;
}

.MuiMenu-list::-moz-scrollbar-track {
  border-radius: 10px;
  background-color: #ddd;
} */

.MuiMenu-list::-moz-scrollbar {
  -webkit-appearance: none;
}

.MuiList-root.MuiList-padding.MuiMenu-list li {
  border-bottom: 1.5px solid #dddddd;
  font-size: 16px;
  line-height: 18px;
  padding: 12px 20px;
  color: #3f4444;
  font-family: "Noto Serif", serif !important;
  background-color: transparent;
}

.ElementsApp .InputElement.is-invalid {
  color: #3f4444 !important;
  font-family: "Noto Serif", serif !important;
}

.MuiList-root.MuiList-padding.MuiMenu-list li:last-child {
  border-bottom: none;
}

.navbar .Mui-selected a {
  background-color: #ffc200 !important;
  color: #0f121d !important;
}

/* loader css */
.loader-container.body {
  z-index: 9999 !important;
  background: rgba(0, 0, 0, 0.4) !important;
}

.loader.body {
  background-color: transparent !important;
}

.loader .spinner {
  border-top-color: #ffc107 !important;
}

.loader.box .message,
.loader.body .message {
  color: #ffc107 !important;
  font-weight: 700;
  font-size: 18px;
}

.MuiButtonBase-root.MuiPickersDay-root.MuiDateRangePickerDay-day.Mui-selected {
  color: #3f4444;
  background-color: #ffc200;
}

.MuiTooltip-arrow {
  color: #fff !important;
}

.MuiTooltip-tooltip {
  background-color: #fff !important;
  color: #3f4444 !important;
  padding: 10px !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-family: "Noto Serif", serif !important;
  font-weight: 400 !important;
  line-height: 14px !important;
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 30%) !important;
}

.apexcharts-legend-series {
  margin: 0 5px !important;
  display: flex;
  align-items: center;
}

.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  flex-direction: column-reverse !important;
  top: 50% !important;
  bottom: auto !important;
}

.apexcharts-pie-label,
.apexcharts-datalabels,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
  font-size: 40px !important;
  font-family: "Bebas Neue", serif !important;
  font-weight: 400 !important;
  line-height: 40px !important;
  text-shadow: none;
  fill: #3f4444 !important;
}

.apexcharts-tooltip.apexcharts-theme-dark {
  display: none !important;
}

.apexcharts-series.apexcharts-pie-series path {
  filter: none !important;
}

.apexcharts-slices g:nth-child(3) {
  transform: translate(-25px, 35px);
}

.apexcharts-slices g:nth-child(4) {
  transform: translate(25px, -50px);
}

.legend-mouseover-inactive {
  opacity: 1 !important;
}

line {
  stroke: #f1eedb;
}

.apexcharts-xaxis-tick line {
  stroke: #f1eedb;
}

.StripeElement {
  color: #3f4444;
  width: 100%;
  border: 1px solid #fff;
  min-height: 40px;
  outline: none;
  padding: 12px 20px;
  font-size: 16px;
  background: #fff !important;
  transition: 0.5s ease;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  line-height: 24px;
  border-radius: 30px;
}

.StripeElement--focus {
  border-radius: 30px;
  box-shadow: 0 1px 3px 0 #fff;
}

.StripeElement--invalid {
  border-radius: 30px;
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.MuiTabs-indicator {
  display: none !important;
}

.apexcharts-datalabels text {
  font-size: 32px !important;
  font-weight: 400 !important;
  text-align: center;
  display: block;
  filter: none;
}

.MuiAlert-action button {
  min-width: auto !important;
  padding: 5px !important;
}

.req {
  font-size: 15px;
  color: #3f4444;
  padding: 0 0 0 3px;
  display: inline-block;
}

.MuiAccordionSummary-expandIconWrapper img,
.MuiAccordionSummary-expandIconWrapper svg {
  display: none;
}

.MuiAccordionSummary-content::after {
  content: "";
  height: 18px;
  width: 18px;
  position: absolute;
  right: 0;
  background-image: url("../public/images/arrow-close.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.MuiAccordionSummary-content.Mui-expanded::after {
  content: "";
  background-image: url("../public/images/arrow-open.svg");
}

.MuiPaginationItem-root {
  font-size: 14px !important;
  display: inline-block !important;
  align-items: center !important;
  height: 32px !important;
  width: 32px;
  align-content: center !important;
  line-height: 32px !important;
  vertical-align: middle !important;
  font-family: "Noto Serif", serif !important;
  /* opacity: 1 !important;   */
  cursor: pointer !important;
}

.errorMessage {
  color: #df3f51;
  margin: 5px 0px 0 10px !important;
  display: block;
  font-size: 13px;
  font-family: "Noto Serif", serif;
  font-weight: 400;
  line-height: 16px;
  /* text-transform: lowercase; */
}

.errorMessage::first-letter {
  /* text-transform: capitalize; */
}

.closeIcon {
  right: 80px !important;
  padding: 0;
  background: transparent !important;
  padding: 10px 15px !important;
}

.closeIcon svg {
  color: "#3f4444 !important";
}

.apexcharts-text tspan {
  font-size: 9px;
}
.apexcharts-xaxis-tick, .apexcharts-gridlines-vertical line{
  stroke: #fff;
}

@media (max-width: 1199px) {
  .MuiAppBar-positionFixed > div {
    min-height: 40px !important;
  }

  .LeftSpace {
    padding: 40px 0 0 0;
  }

  .MuiAppBar-positionFixed button svg {
    color: #3f4444;
  }

  .css-10vjdhg-MuiPaper-root-MuiAppBar-root {
    background-color: rgb(227 221 210) !important;
  }

  h3 {
    font-size: 20px;
    line-height: 20px;
  }

  .MuiTableCell-head {
    font-size: 16px;
    line-height: 16px;
    min-width: 150px;
  }
}

/* media 991 */
@media (max-width: 991px) {
  .apexcharts-pie-label,
  .apexcharts-datalabels,
  .apexcharts-datalabel,
  .apexcharts-datalabel-label,
  .apexcharts-datalabel-value {
    font-size: 20px !important;
    line-height: 20px !important;
  }

  .apexcharts-datalabels text {
    font-size: 20px !important;
  }
}

@media (max-width: 375px) {
  .errorMessage {
    font-size: 12px;
    margin-right: 0 !important;
  }
}
